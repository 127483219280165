
import { defineComponent } from "vue";
import NotificationPopup from "@/components/notifications/NotificationPopup.vue";
import CopyIcon from "@/components/icons/CopyIcon.vue";
import {WidgetI} from "@/interfaces/interfaces";
import WidgetCompiled from '@/components/WidgetCompiled.vue';
import { getEmbedCode } from '@/common/helpers';
import { WIDGET_SCRIPT, API_URL } from '@/common/constants';
import { changeCase } from "../../helpers";
import { ElementE } from '../../enums';

export default defineComponent({
  name: "PreviewTab",
  components: { NotificationPopup, CopyIcon, WidgetCompiled },
  props: ['widgets', 'isSavedWidget'],
  emits:['updateWidget', 'copied'],
  data(){
    return {
      tmpWidgetId: '996873de-8656-4b64-bca9-806a7cfd3a20',
      widgetId: ' ' as string,
      widget:{} as WidgetI,
      embedCode: '' as string,
      api: '' as string,
      isFirstLoad: true as boolean,

      changeCase: changeCase,
      ElementE: ElementE,
    }
  },
  methods: {
    copyEmbedCode: function() {
      navigator.clipboard.writeText(this.embedCode);
      this.$emit('copied');
    },
    selectAll() {
      (this.$refs.textarea as HTMLTextAreaElement).select();
    },
    previewTabHandler(widgets: WidgetI) {
      this.widget = widgets
      this.widgetId = widgets.id


      this.embedCode = getEmbedCode(widgets.id);
      const BASE_URL = `${window.location.protocol}//${window.location.host}`;
      if (this.isFirstLoad) {
        let widgetScript = document.createElement('script');
        let scriptSrc = WIDGET_SCRIPT;
        if (WIDGET_SCRIPT.startsWith('/')) {
          scriptSrc = `${BASE_URL}${WIDGET_SCRIPT}`;
        }
        widgetScript.setAttribute('src', scriptSrc);
        document.body.appendChild(widgetScript);
        this.isFirstLoad = false;
      }
      if (API_URL.startsWith('/')) {
        this.api = `${BASE_URL}${API_URL}`;
      } else {
        this.api = API_URL;
      }
    }
  },
  mounted() {
    this.previewTabHandler(this.widgets);
  },
  watch: {
    widgets: {
      handler(widgets: WidgetI) {
        this.previewTabHandler(widgets);
      }, deep: true
    }
  },
});
