import { WIDGET_SCRIPT, API_URL } from '@/common/constants';

export function getEmbedCode(widgetId: string): string {
    const BASE_URL = `${window.location.protocol}//${window.location.host}`;
    let scriptSrc = WIDGET_SCRIPT;
    if (WIDGET_SCRIPT.startsWith('/')) {
        scriptSrc = `${BASE_URL}${WIDGET_SCRIPT}`;
    }

    let apiUrl = API_URL;
    if (API_URL.startsWith('/')) {
        apiUrl = `${BASE_URL}${API_URL}`;
    }

    return `<script defer="defer" src="${scriptSrc}"></script>`
        + `<rr-resnova widget-id= ${widgetId} api-url=${apiUrl}></rr-resnova>`;
}
